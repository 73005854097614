import React, { useEffect, useState } from 'react'
import { Grid, Typography, Box, Button, TextField, CircularProgress } from '@mui/material';
import { IMAGES } from '../utils/constants';

import CustomTextField from '../components/common/customTextField';

import { useDispatch, useSelector } from 'react-redux'
import { updateForm } from '../redux/form';
import { nextStep } from '../redux/step'

import ValuationService from '../services/ValuationService';

import { Trans, useTranslation } from 'react-i18next';

const Step5 = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const form = useSelector(state => state.form)

    const [valuation, setValuation] = useState("");
    const [open, setOpen] = useState(true);

    const [loading, setLoading] = useState(false);

    const gun = new Date().getDate();
    const ay = (new Date().getMonth() + 1).toString().padStart(2, '0');
    const yil = new Date().getFullYear();

    // Get vehicle valuation
    useEffect(() => {
        GetVehicleValuation()
    }, [])

    const GetVehicleValuation = async () => {
        try {
            setLoading(true);
            const response = await ValuationService.GetVehicleValuation(form);
            if (response.data.valuation === "") {
                setOpen(false)
            }
            setValuation(response.data.valuation);
        } catch (error) {
            setOpen(false)
            console.error(error);
        } finally {
            setLoading(false);
        }
        return;
    }

    return (
        <div>
            <Box pb={2} display="flex" justifyContent="center" alignItems="center" overflow="hidden">
                <img src={IMAGES.NIAGARA} alt="Resim Açıklaması" style={{ width: '100%' }} />
            </Box>

            <Grid px={3} pb={4}>
                <Typography variant="h5"> {form.vehicleBrand} {form.vehicleModel}  </Typography>
                <Typography variant="h6"> {form.vehicleShapeType} {form.vehicleEngineNameHP} </Typography>
                <Typography variant="subtitle1"> {form.vehicleYear} Model | {form.vehicleGearType} Vites | {form.vehicleFuelType} | {form.km} KM </Typography>
                {
                    open ? (
                        <Typography variant="body1" pt={1} lineHeight={1} > {t("step5SubTitleValuation1")} </Typography>
                    ) : (
                        <Typography variant="body1" pt={1} lineHeight={1} > {t("step5SubTitleValuation2")}  </Typography>
                    )
                }
            </Grid>

            <Grid px={3} pb={3} textAlign="center">
                <Box p={2} bgcolor={"rgba(216, 216, 216, 0.32)"} >
                    {loading ? <CircularProgress /> :
                        open === true && <Typography variant="h4" >{valuation.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")} TL</Typography>
                    }
                    <Typography variant="subtitle2" >{gun}.{ay}.{yil}</Typography>
                </Box>
            </Grid>

            <Grid px={3} pb={3}>
                <Typography variant="body2" lineHeight={1}>
                    <Trans i18nKey="step5Conditions" />
                </Typography>

            </Grid>

        </div>
    )
}

export default Step5