// Interceptor 
import axios from '../utils/interceptor'
import { API_KEY, HEADERS } from '../utils/constants'

class ValuationService {

    GetVehiclesFiltered = async (makeName, modelName, vehicleYear, vehicleGearType, vehicleFuelType, vehicleShapeType, vehicleTrimName, vehicleEngineName) => {
        let body = {
            makeName: makeName,
            modelName: modelName,
            vehicleYear: vehicleYear,
            transmissionType: vehicleGearType,
            fuelClass: vehicleFuelType,
            bodyShapeName: vehicleShapeType,
            trimName: vehicleTrimName,
            engineName: vehicleEngineName
        }
        try {
            // Request
            let result = await axios.post('/valuation/filteredVehicles', body)
            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            // Return 
            return result.data.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

    GetBrandsAndModels = async () => {
        try {
            // Request
            let result = await axios.get('/valuation/catalog/brands-models')
            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

    GetVehicleInfo = async (licensePlate) => {
        let body = {
            licensePlate: licensePlate
        }
        try {
            // Request
            let result = await axios.post('/valuation/getVehicle', body)
            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

    GetVehicleValuation = async (data) => {
        let body = {
            "vehicle": {
                "makeName": data.vehicleBrand,
                "modelName": data.vehicleModel,
                "year": data.vehicleYear,
                "km": data.km,
                "plate": data.licensePlate,
                "transmissionType": data.vehicleGearType,
                "fuelClass": data.vehicleFuelType,
                "bodyShapeName": data.vehicleShapeType,
                "trimName": data.vehicleTrimName,
                "engineName": data.vehicleEngineName,
                "faceLiftFrom": data.vehicleFaceLiftFrom,
                "faceLiftTo": data.vehicleFaceLiftTo
            },
            "user": {
                "name": data.name,
                "surname": data.surname,
                "phone": data.phone,
                "email": data.email,
                "reason": data.howCanWeHelp,
                "dealerCode": data.dealer,
            },
            "expertize": { color: data.vehicleColor, paint_type: data.vehiclePaintType, ...data.expertize },
            "dataSource": data.dataSource
        }

        try {
            // Request
            let result = await axios.post('/valuation/v2/vehicle', body)

            //Check Error
            if (result.data.error) return Promise.reject(result.data.error)

            //Return
            return result.data

        } catch (e) {
            return Promise.reject(e)
        }
    }

}

export default new ValuationService()