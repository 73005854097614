import React, { useEffect, useState, useMemo, useRef } from 'react';

import { Box, Stepper, Step, StepLabel, Button, Grid } from '@mui/material';

import { previousStep } from './../redux/step'
import { useDispatch, useSelector } from 'react-redux'
import Step5 from '../routes/step5'

import { updateForm } from '../redux/form';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const steps = ['', '', '', '', ''];

export default function HorizontalLinearStepper(props) {
  const dispatch = useDispatch()
  const activeStep = useSelector(state => state.step)

  useEffect(() => {
    dispatch(updateForm({
      dataSource: props.source
    }))
  }, [props.source])

  const styles = {
    divBorder: {
      width: '22px',
      height: '22px',
      borderRadius: '50%',
      border: '1px solid #fff',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    divBorder2: {
      position: 'relative',
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      background: '#fff',
      border: '2px solid #000',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    divBorderActive: {
      position: 'relative',
      width: '20px',
      height: '20px',
      borderRadius: '50%',
      border: '2px solid #000',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  }

  const handleBack = () => {
    // Go previous step
    dispatch(previousStep())
  };

  return (
    <Box maxWidth={"468px"} border={1} borderColor={"lightGray"}>
      {activeStep >= 4 ? null :

        <Grid container backgroundColor='black' minHeight={"40px"}>
          <Grid item xs={5}>
            <Button onClick={handleBack} variant="contained" disableElevation disabled={activeStep == 0 || activeStep >= 4} startIcon={
              activeStep === 0 || activeStep >= 4 ? null :
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="25" viewBox="0 0 16 25" fill="none">
                  <path d="M1.03523 13.0518C0.725234 12.749 0.725234 12.251 1.03523 11.9482L10.6352 2.57324C10.9452 2.27051 11.4552 2.27051 11.7652 2.57324C12.0752 2.87598 12.0752 3.37402 11.7652 3.67676L2.73023 12.5L11.7652 21.3232C12.0752 21.626 12.0752 22.124 11.7652 22.4268C11.4552 22.7295 10.9452 22.7295 10.6352 22.4268L1.03523 13.0518Z" fill="white" />
                </svg>
            }>
              {
                activeStep === 0 ? null :
                  activeStep === 1 ? "Araç Bilgileri" :
                    activeStep === 2 ? "Marka Seçimi" :
                      activeStep === 3 ? "Hasar/Aksesuar" : null
              }

            </Button>
          </Grid>
          <Grid item xs={7} alignSelf='center' >
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label, index) => (
                <Step key={index} >
                  <StepLabel
                    icon={
                      activeStep > index ? (
                        <div style={styles.divBorder}>
                          <div style={styles.divBorder2}>
                            <CheckOutlinedIcon style={{ fontSize: 15 }} />
                          </div>
                        </div>
                      ) : (
                        <div style={styles.divBorder}>
                          <div style={{ ...styles.divBorderActive, background: index === activeStep ? '#FFEB3B' : '#000' }}>
                            {index === activeStep && <KeyboardArrowRightIcon style={{ fontSize: 20 }} />}
                          </div>
                        </div>
                      )
                    }
                  >
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>
      }
      {
        activeStep === steps.length ? (
          <React.Fragment>
            <Step5 />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {props.children}
          </React.Fragment>
        )
      }
    </Box >
  );
}