const DEFAULT_LANGUAGE = 'tr';

const API_KEY = {
    DEALERS_TOKEN: process.env.REACT_APP_DEALERS_TOKEN,
    PHONE_VERIFICATION_TOKEN: process.env.REACT_APP_PHONE_VERIFICATION_TOKEN,
}

const ROUTES = {
    INDEX: '/',
    NOT_FOUND: '/404',
    LINK_NOT_FOUND: '/',
}

const IMAGES = {
    RENAULT_LOGO_MAIN: 'renault_logo_white.png',
    MAIN_PAGE_IMG: 'main.png',
    FORM_PAGE_IMG: 'form.png',
    ERROR_PAGE_IMG: 'error.jpg',

    CAPTUR: 'Images/captur.png',
    NIAGARA: 'Images/niagara.png',
    SPRING: 'Images/spring.png',

    // Brands
    RENAULT: 'Images/brands/renault.png',
    DACIA: 'Images/brands/dacia.png',
}

const COOKIES = {
    AUTHORIZATION: 'authorization',
    LANGUAGE: 'language',
    DEVICE: 'device-id',
};

const HEADERS = {
    AUTHORIZATION: 'authorization',
    LANGUAGE: 'language',
    DEVICE: 'device-id',
    DEVICE_OS: 'device-os',
    CUSTOMER_TYPE: 'customer-type'
}

/**
 * #TODO 
 * Add public url which won't have token in header when you send a request.
 *   */
const PUBLIC_URL_LIST = [
    '/login'
]

const STEPS = {
    'step0': 0,
    'step1': 1,
    'step2': 2,
    'step3': 3,
    'step4': 4,
    'step5': 5
}

const RESPONSE_STATUSES = {
    success: "Success",
    fail: "Fail"
}

const UNKNOWN_ERROR = {
    tr: "Bilinmeyen bir hata oluştu.",
    en: "An unknown error has occurred."
}

export {
    API_KEY,
    IMAGES,
    DEFAULT_LANGUAGE,
    ROUTES,
    STEPS,
    RESPONSE_STATUSES,
    UNKNOWN_ERROR,
    PUBLIC_URL_LIST,
    HEADERS,
    COOKIES
}