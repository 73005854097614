import { React } from 'react'

// i18n
import './utils/i18n';

// Redux
import store from './redux/store';
import { Provider } from 'react-redux'

// MUI
import { ThemeProvider } from '@mui/material/styles';

// Utils
import { useThemes } from './utils/useThemes';

// Custom
import Index from './routes';
import CustomSnackbar from './components/common/customSnackbar';

function App() {
  const getTheme = useThemes()

  return (
    <Provider store={store}>
      <ThemeProvider theme={getTheme()}>
        <CustomSnackbar />
        <Index />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
