import React, { useEffect, useState } from 'react'
import { Grid, Typography, Button } from '@mui/material';
import CustomSelect from '../components/common/customSelect';

import { useDispatch, useSelector } from 'react-redux'
import { updateForm } from '../redux/form';
import { nextStep } from '../redux/step'

import ValuationService from '../services/ValuationService';
import { useTranslation } from 'react-i18next';

const Step2 = () => {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const form = useSelector(state => state.form)

    // Form states
    const [vehicleGearType, setVehicleGearType] = useState(form.vehicleGearType)
    const [vehicleFuelType, setVehicleFuelType] = useState(form.vehicleFuelType)
    const [vehicleShapeType, setVehicleShapeType] = useState(form.vehicleShapeType)
    const [vehicleEngineNameHP, setVehicleEngineNameHP] = useState(form.vehicleEngineNameHP)
    const [vehicleTrimName, setVehicleTrimName] = useState(form.vehicleTrimName)
    const [vehicleFaceLift, setVehicleFaceLift] = useState(form.vehicleFaceLift)
    const [vehicleColor, setVehicleColor] = useState(form.vehicleColor)
    const [vehiclePaintType, setVehiclePaintType] = useState(form.vehiclePaintType)

    const [vehicleEngineName, setVehicleEngineName] = useState(form.vehicleEngineName)
    const [vehicleFaceLiftFrom, setVehicleFaceLiftFrom] = useState(form.vehicleFaceLiftFrom)
    const [vehicleFaceLiftTo, setVehicleFaceLiftTo] = useState(form.vehicleFaceLiftTo)

    // Form error states
    const [vehicleGearTypeError, setVehicleGearTypeError] = useState(false)
    const [vehicleFuelTypeError, setVehicleFuelTypeError] = useState(false)
    const [vehicleShapeTypeError, setVehicleShapeTypeError] = useState(false)
    const [vehicleEngineNameError, setVehicleEngineNameError] = useState(false);
    const [vehicleTrimNameError, setVehicleTrimNameError] = useState(false);
    const [vehicleFaceLiftError, setVehicleFaceLiftError] = useState(false);
    const [vehicleColorError, setVehicleColorError] = useState(false);
    const [vehiclePaintTypeError, setVehiclePaintTypeError] = useState(false);

    // Dropdown lists
    const [gearTypeList, setGearTypeList] = useState([])
    const [fuelTypeList, setFuelTypeList] = useState([])
    const [shapeTypeList, setShapeTypeList] = useState([])
    const [engineNameList, setEngineNameList] = useState([]);
    const [trimNameList, setTrimNameList] = useState([]);
    const [vehicleFaceLiftList, setVehicleFaceLiftList] = useState([]);

    const vehicleColorList = [
        { value: "Beyaz", key: "white" },
        { value: "Siyah", key: "black" },
        { value: "Kırmızı", key: "red" },
        { value: "Gri", key: "gray" },
        { value: "Yeşil", key: "green" },
        { value: "Sarı", key: "yellow" },
        { value: "Turuncu", key: "orange" },
        { value: "Gümüş", key: "silver" },
        { value: "Mavi", key: "blue" },
        { value: "Lacivert", key: "dark_blue" },
        { value: "Şampanya", key: "champagne" },
        { value: "Altın", key: "gold" },
        { value: "Kahverengi", key: "brown" },
        { value: "Bej", key: "beige" },
        { value: "Diğer", key: "other" }
    ];
    const vehiclePaintTypeList = [
        { value: "Metalik", key: "metallic" },
        { value: "Düz", key: "solid" },
        { value: "Mat", key: "matt" },
        { value: "Kaplanmış", key: "wrapped" }
    ];

    const [response, setResponse] = useState([{}])
    const [loading, setLoading] = useState(false);
    const [faceLiftStatus, setFaceLiftStatus] = useState(false);

    // Get data
    useEffect(() => {
        GetData()
    }, [])

    const GetData = async () => {
        try {
            const response = await ValuationService.GetVehiclesFiltered(form.vehicleBrand, form.vehicleModel, form.vehicleYear);
            setResponse(response.vehiclesArray)
        } catch (error) {
            console.error(error);
        }
        return;
    }

    // Get gear types list
    useEffect(() => {
        GetVehiclesGearType()
    }, [response])

    const GetVehiclesGearType = async () => {
        try {
            const uniqueGearTypes = Array.from(new Set(response.map(item => item.transmissionType))).map((gearType) => ({ key: gearType, value: gearType }));
            setGearTypeList(uniqueGearTypes)
        } catch (error) {
            console.error(error);
        }
        return;
    };

    // Get fuel types list
    useEffect(() => {
        GetVehiclesFuelType(vehicleGearType)
    }, [vehicleGearType, response])

    const GetVehiclesFuelType = async (gear) => {
        try {
            const filtered = response.filter(item => item.transmissionType === gear);
            const uniqueFuelTypes = Array.from(new Set(filtered.map(item => item.fuelClass))).map((fuelType) => ({ key: fuelType, value: fuelType }));
            setFuelTypeList(uniqueFuelTypes)
        } catch (error) {
            console.error(error);
        }
        return;
    };

    // Get shape types list
    useEffect(() => {
        GetVehiclesShapeType(vehicleGearType, vehicleFuelType)
    }, [vehicleFuelType, response])

    const GetVehiclesShapeType = async (gear, fuel) => {
        try {
            const filtered = response.filter(item => item.fuelClass === fuel && item.transmissionType === gear);
            const uniqueShapeTypes = Array.from(new Set(filtered.map(item => item.bodyShapeName))).map((shapeType) => ({ key: shapeType, value: shapeType }));
            setShapeTypeList(uniqueShapeTypes)
        } catch (error) {
            console.error(error);
        }
        return;
    };

    // Get engine names list
    useEffect(() => {
        GetVehiclesEngineName(vehicleGearType, vehicleFuelType, vehicleShapeType)
    }, [vehicleShapeType, response])

    const GetVehiclesEngineName = async (gear, fuel, shape) => {
        try {
            const filtered = response.filter(item => item.bodyShapeName === shape && item.fuelClass === fuel && item.transmissionType === gear);
            const uniqueEngineNames = Array.from(new Set(filtered.map(item => item.engineNameHP))).map((engineNameHP) => ({ key: engineNameHP, value: engineNameHP }));
            setEngineNameList(uniqueEngineNames)
        } catch (error) {
            console.error(error);
        }
        return;
    };

    // Get trim names list
    useEffect(() => {
        GetVehiclesTrimName(vehicleGearType, vehicleFuelType, vehicleShapeType, vehicleEngineNameHP)
    }, [vehicleEngineNameHP, response])

    const GetVehiclesTrimName = async (gear, fuel, shape, engineNameHP) => {
        try {
            const filtered = response.filter(item => item.bodyShapeName === shape && item.fuelClass === fuel && item.transmissionType === gear && item.engineNameHP === engineNameHP);
            const uniqueTrimNames = Array.from(new Set(filtered.map(item => item.trimName))).map((trimName) => ({ key: trimName, value: trimName }));
            setTrimNameList(uniqueTrimNames)
        }
        catch (error) {
            console.error(error);
        }
        return;
    }

    // Get trim name list
    useEffect(() => {
        GetVehiclesFaceLiftOrEngineName(vehicleGearType, vehicleFuelType, vehicleShapeType, vehicleEngineNameHP, vehicleTrimName)
    }, [vehicleTrimName, response])

    const GetVehiclesFaceLiftOrEngineName = async (gear, fuel, shape, engineNameHP, trimName) => {
        try {
            const filtered = response.filter(item => item.bodyShapeName === shape && item.fuelClass === fuel && item.transmissionType === gear && item.engineNameHP === engineNameHP && item.trimName === trimName);
            if (filtered.length == 1) {
                setFaceLiftStatus(false)
                setVehicleEngineName(filtered[0]?.engineName)
                setVehicleFaceLiftFrom(filtered[0]?.faceLiftFrom)
                setVehicleFaceLiftTo(filtered[0]?.faceLiftTo)
            }
            else {
                setFaceLiftStatus(true)
                const response = await ValuationService.GetVehiclesFiltered(form.vehicleBrand, form.vehicleModel, form.vehicleYear, vehicleGearType, vehicleFuelType, vehicleShapeType, vehicleTrimName, filtered[0]?.engineName);
                const uniqueFaceLifts = Array.from(new Set(response.vehiclesArray.map(item => item.faceLift))).map((faceLift) => ({ key: faceLift, value: faceLift }));
                setVehicleFaceLiftList(uniqueFaceLifts)
            }
        }
        catch (error) {
            console.error(error);
        }
        return;
    }

    // Next step and form validation
    const handleNext = () => {
        // Form validation
        let error = false;
        if (!vehicleGearType) {
            error = true
            setVehicleGearTypeError(true)
        }
        if (!vehicleFuelType) {
            error = true
            setVehicleFuelTypeError(true)
        }
        if (!vehicleShapeType) {
            error = true
            setVehicleShapeTypeError(true)
        }
        if (!vehicleEngineNameHP) {
            error = true
            setVehicleEngineNameError(true)
        }
        if (!vehicleTrimName) {
            error = true
            setVehicleTrimNameError(true)
        }
        if (faceLiftStatus) {
            if (!vehicleFaceLift) {
                error = true
                setVehicleFaceLiftError(true)
            }
        }
        if (!vehicleColor) {
            error = true
            setVehicleColorError(true)
        }
        if (!vehiclePaintType) {
            error = true
            setVehiclePaintTypeError(true)
        }


        if (!error) {
            // Save info to redux and proceed
            dispatch(updateForm({
                vehicleGearType,
                vehicleFuelType,
                vehicleShapeType,
                vehicleEngineNameHP,
                vehicleTrimName,
                vehicleFaceLift,

                vehicleEngineName,
                vehicleFaceLiftFrom,
                vehicleFaceLiftTo,

                vehicleColor,
                vehiclePaintType
            }))
            setLoading(true);
            // Next step
            dispatch(nextStep())
        }
    };

    return (
        <Grid container>
            <Grid container justifyContent={'center'} p={2}>
                <Typography variant="h4" >{t("step2Title")} </Typography>
            </Grid>

            <Grid item px={3} xs={12}>
                <CustomSelect
                    error={vehicleGearTypeError}
                    errorText={t("step2GearTypeError")}
                    sendInfo={t("step2GearType")}
                    value={vehicleGearType}
                    items={gearTypeList}
                    onChange={e => {
                        setVehicleGearType(e.target.value);
                        setVehicleFuelType("")
                        setVehicleShapeType("")
                        setVehicleEngineNameHP("")
                        setVehicleEngineName("")
                        setVehicleTrimName("")
                        setVehicleFaceLift("")
                        setVehicleFaceLiftFrom("")
                        setVehicleFaceLiftTo("")
                        setVehicleColor("")
                        setVehiclePaintType("")
                        if (e.target.value) {
                            setVehicleGearTypeError(false)
                        }
                    }}
                />
            </Grid>
            <Grid item px={3} xs={12}>
                <CustomSelect
                    fullWidth
                    error={vehicleFuelTypeError}
                    errorText={t("step2FuelTypeError")}
                    sendInfo={t("step2FuelType")}
                    value={vehicleFuelType}
                    items={fuelTypeList}
                    disabled={!vehicleGearType}
                    onChange={e => {
                        setVehicleFuelType(e.target.value);
                        setVehicleShapeType("")
                        setVehicleEngineNameHP("")
                        setVehicleEngineName("")
                        setVehicleTrimName("")
                        setVehicleFaceLift("")
                        setVehicleFaceLiftFrom("")
                        setVehicleFaceLiftTo("")
                        setVehicleColor("")
                        setVehiclePaintType("")
                        if (e.target.value) {
                            setVehicleFuelTypeError(false)
                        }
                    }}
                />
            </Grid>
            <Grid item px={3} xs={12}>
                <CustomSelect
                    error={vehicleShapeTypeError}
                    errorText={t("step2ShapeTypeError")}
                    sendInfo={t("step2ShapeType")}
                    value={vehicleShapeType}
                    items={shapeTypeList}
                    disabled={!vehicleFuelType}
                    onChange={e => {
                        setVehicleShapeType(e.target.value);
                        setVehicleEngineNameHP("")
                        setVehicleEngineName("")
                        setVehicleTrimName("")
                        setVehicleFaceLift("")
                        setVehicleFaceLiftFrom("")
                        setVehicleFaceLiftTo("")
                        setVehicleColor("")
                        setVehiclePaintType("")
                        if (e.target.value) {
                            setVehicleShapeTypeError(false)
                        }
                    }} />
            </Grid>

            <Grid item px={3} xs={12}>
                <CustomSelect
                    error={vehicleEngineNameError}
                    errorText={t("step2EngineError")}
                    sendInfo={t("step2Engine")}
                    value={vehicleEngineNameHP}
                    items={engineNameList}
                    disabled={!vehicleShapeType}
                    onChange={e => {
                        setVehicleEngineNameHP(e.target.value);
                        setVehicleTrimName("")
                        setVehicleFaceLift("")
                        setVehicleFaceLiftFrom("")
                        setVehicleFaceLiftTo("")
                        setVehicleColor("")
                        setVehiclePaintType("")
                        if (e.target.value) {
                            setVehicleEngineNameError(false);
                        }
                    }}
                />
            </Grid>

            <Grid item px={3} xs={12}>
                <CustomSelect
                    error={vehicleTrimNameError}
                    errorText={t("step2TrimNameError")}
                    sendInfo={t("step2TrimName")}
                    value={vehicleTrimName}
                    items={trimNameList}
                    disabled={!vehicleEngineNameHP}
                    onChange={e => {
                        setVehicleTrimName(e.target.value);
                        setVehicleFaceLift("")
                        setVehicleFaceLiftFrom("")
                        setVehicleFaceLiftTo("")
                        setVehicleColor("")
                        setVehiclePaintType("")
                        if (e.target.value) {
                            setVehicleTrimNameError(false);
                        }
                    }}
                />
            </Grid>

            {faceLiftStatus &&
                <Grid item px={3} xs={12}>
                    <CustomSelect
                        error={vehicleFaceLiftError}
                        errorText={t("step2VehicleFaceLiftError")}
                        sendInfo={t("step2VehicleFaceLift")}
                        value={vehicleFaceLift}
                        items={vehicleFaceLiftList}
                        disabled={!vehicleTrimName}
                        onChange={e => {
                            setVehicleColor("")
                            setVehiclePaintType("")
                            setVehicleFaceLift(e.target.value);
                            if (e.target.value) {
                                setVehicleFaceLiftError(false);
                            }
                        }}
                    />
                </Grid>
            }

            <Grid item px={3} xs={12}>
                <CustomSelect
                    error={vehicleColorError}
                    errorText={t("step2ColorError")}
                    sendInfo={t("step2Color")}
                    value={vehicleColor}
                    items={vehicleColorList}
                    disabled={!vehicleTrimName || (faceLiftStatus && !vehicleFaceLift)}
                    onChange={e => {
                        setVehiclePaintType("")
                        setVehicleColor(e.target.value);
                        if (e.target.value) {
                            setVehicleColorError(false);
                        }
                    }}
                />
            </Grid>

            <Grid item px={3} xs={12}>
                <CustomSelect
                    error={vehiclePaintTypeError}
                    errorText={t("step2PaintTypeError")}
                    sendInfo={t("step2PaintType")}
                    value={vehiclePaintType}
                    items={vehiclePaintTypeList}
                    disabled={!vehicleColor}
                    onChange={e => {
                        setVehiclePaintType(e.target.value);
                        if (e.target.value) {
                            setVehiclePaintTypeError(false);
                        }
                    }}
                />
            </Grid>

            <Grid item px={3} pt={20} pb={3} xs={12}>
                <Button variant="contained" disabled={loading} onClick={handleNext} fullWidth>{t("step2ContinueButton")}</Button>
            </Grid>
        </Grid>
    )
}

export default Step2